<template>

  <div style="">
    <portal to="title-page">
      <h1>Responsivas:  {{reservation ? reservation.code : 'Por favor espere'}}</h1>
    </portal>
    <Loading :active="loading"/>
    <Responsives v-if="reservation" :reservation="reservation"/>
  </div>
</template>
<script>
  import Vue from "vue";
  import Reservation from "@/models/Reservation";
  import Loading from "@/components/Loading";
  import Responsives from "@/components/reservations/Responsives";
  Vue.use('Loading', Loading);
  //Vue.component('History', History);
  export default {
    name:'TaquillaReservationResponsives',
    components:{Responsives},
    data() {
      return {
        reservationId:this.$route.params.id,
        reservationModel:new Reservation(),
        reservation:null,
        unsubscribe:null,
        loading:true,
      }
    },
    created () {
      this.$store.commit('SET_LAYOUT', 'Taquilla')
    },
    mounted() {
      this.unsubscribe = this.reservationModel.getById(this.reservationId).onSnapshot(snap => {
        this.reservation = snap.data()
        this.reservation.id = snap.id
        this.loading = false;
      })
    },
    methods:{
    },
    //computed: mapState(['dialogConfirmedInfo'])
  }
</script>