<style>
  .theme--light.v-input--is-disabled .v-input__slot{
    background-color: rgba(118, 118, 118, 0.3) !important;
  }
  .theme--light.v-input--is-disabled .v-input__slot input{
    cursor: not-allowed;
  }
</style>
<template>
  <div>
    <Loading :active="loading"/>
    <v-card
      class="mx-auto elevation-1"
    >
      <v-card-title class="align-center" >
        <span>Clientes</span>
        <div class="ml-auto">
          <v-select
            v-model="responsiveLang"
            :items="languages"
            label="Idioma Responsiva"
          ></v-select>
        </div>
        <v-btn v-if="reservation.responsives" class="ml-2" color="red white--text" @click="printResponsives">
          <v-icon>mdi-printer</v-icon> Imprimir
        </v-btn>
        <v-btn class="ml-2" color="primary" @click="goToReservation">
          <v-icon>mdi-arrow-left</v-icon> {{ reservation.responsives ? 'Regresar' : 'Ver reservación' }}
        </v-btn>
        <!-- <v-btn class="ml-auto" dark x-small color="#f88511" @click="dialogAddCustomer = true">
          <v-icon>mdi-plus-thick</v-icon>Cliente
        </v-btn> -->
      </v-card-title>
      <v-divider></v-divider>
      <v-form
        ref="form"
        v-model="formTable"
      >
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left" width="70px">
                  Adultos
                </th>
                <th class="text-left" width="300px">
                  Nombre
                </th>
                <th class="text-left">
                  Tour
                </th>
                <th class="text-left">
                  Firma
                </th>
                <th class="text-left">
                  Acciones
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(c, index) in customers" :key="index">
                <td>
                  <v-checkbox
                    v-model="customers[index].checkin"
                    color="success"
                    class="my-0 py-0 mx-0 px-0"
                    hide-details
                  ></v-checkbox>
                </td> 
                <td style="padding-top: 10px;padding-bottom: 10px;">
                  <v-text-field
                    v-model="customers[index].fullName"
                    placeholder="Nombre completo"
                    :rules="customers[index].checkin ? nameRules : []"
                    required
                    :disabled="!customers[index].checkin"
                    dense
                    hide-details
                  />
                </td>
                <td>{{c.tour}}</td>
                <td>
                  <img width="150px" v-if="customers[index].checkin" :src="customers[index].sign"/>
                </td>
                <td>
                  <v-icon
                    class="mr-2"
                    color="#212121"
                    @click="addSign(index)"
                  >
                    mdi-pencil
                  </v-icon>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-simple-table v-if="children.length">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left" width="70px">
                  Menores
                </th>
                <th class="text-left" width="300px">
                  Nombre
                </th>
                <th class="text-left">
                </th>
                <th class="text-left">
                </th>
                <th class="text-left">
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(c, index) in children" :key="index">
                <td>
                  <v-checkbox
                    v-model="children[index].checkin"
                    color="success"
                    class="my-0 py-0 mx-0 px-0"
                    hide-details
                  ></v-checkbox>
                </td> 
                <td style="padding-top: 10px;padding-bottom: 10px;">
                  <v-text-field
                    v-model="children[index].fullName"
                    placeholder="Nombre completo"
                    :rules="children[index].checkin ? nameRules : []"
                    required
                    :disabled="!children[index].checkin"
                    dense
                    hide-details
                  />
                </td>
                <td>{{c.tour}}</td>
                <td>
                </td>
                <td>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-form>
      <v-card-text>
        <v-btn dark color="primary" @click="validateForm" >Guardar</v-btn>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="openDialog"
      width="980"
      persistent
    >
      <v-card v-if="itemSelected !== null" height="90vh">
        <v-card-title class="headline info white--text d-flex">
          <span>Firma: {{ customers[itemSelected].fullName }}</span>
          <!-- <v-btn @click="openDialog = false" class="ml-auto"><v-icon>mdi-close</v-icon></v-btn> -->
          <v-icon @click="openDialog = false" class="ml-auto white--text">mdi-close</v-icon>
        </v-card-title>

        <!-- <v-card-text class="pt-4"> -->
          <v-expansion-panels v-model="panel">
            <v-expansion-panel value="0">
              <v-expansion-panel-header>Responsiva</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-radio-group
                  v-model="lang"
                  row
                  class="my-0 py-0"
                >
                  <v-radio
                    label="Español"
                    value="es"
                    hide-details
                  ></v-radio>
                  <v-radio
                    label="English"
                    value="en"
                    hide-details
                  ></v-radio>
                  <v-btn color="primary" class="ml-auto" @click="goToToSign">Sign/Firmar</v-btn>
                </v-radio-group>
                <ResponsiveTextEs v-if="lang == 'es'"/>
                <ResponsiveTextEn v-if="lang == 'en'"/>
                <v-btn class="ml-auto"  color="primary" @click="goToToSign">Sign/Firmar</v-btn>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel value="1">
              <v-expansion-panel-header>Sign/Firma</v-expansion-panel-header>
              <v-expansion-panel-content>
                <SignCanvas  @export-canvas="handleCanvas" @close-dialog="closeDialog"/>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        <!-- </v-card-text> -->

        <!-- <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="closeDialog"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="primary"
            :disabled=""
            @click="validateForm"
          >
            <v-icon>mdi-content-save</v-icon> Guardar
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="-1"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script type="text/javascript">
  import SignCanvas from "@/components/SignCanvas";
  import ReservationModel from "@/models/Reservation";
  import ResponsiveTextEs from "@/components/reservations/ResponsiveTextEs";
  import ResponsiveTextEn from "@/components/reservations/ResponsiveTextEn";
  import ResponsivePdf from "@/pdfs/Responsive"
  //import Loading from "@/components/Loading";
  export default {
    name: "ReservationResponsives",
    components: {SignCanvas,ResponsiveTextEs,ResponsiveTextEn},
    props: ['reservation'],
    data() {
      const pax = this.reservation.adults + this.reservation.elderlies
      const numbChildren = this.reservation.infants + this.reservation.children
      let customers = []
      let children = []
      if (this.reservation.responsives) {
        for (var c of this.reservation.responsives) {
          customers.push({...c})
        }
        children = this.reservation.childrenNames || []
      } else {
        customers.push({
          fullName: this.reservation.customerName + ' ' + this.reservation.customerLastname,
          tour: this.reservation.tour,
          sign:null,
          checkin:true,
          type:'adult',
        })
        for (var i = 2; i <= pax; i++) {
          customers.push({
            fullName: '',
            tour: this.reservation.tour,
            sign:null,
            checkin:true,
            type:'adult',
          })
        }
        for (var x = 1; x <= numbChildren; x++) {
          children.push({
            fullName: '',
            tour: this.reservation.tour,
            checkin:true,
            type:'child',
          })
        }
      }
      return {
        lang: this.reservation.lang,
        languages:['es', 'en'],
        responsiveLang: this.reservation.lang,
        loading: false,
        openDialog: false,
        formTable: true,
        customers,
        children,
        itemSelected:null,
        panel:0,
        nameRules: [
          v => !!v || 'El nombre es obligatorio',
          //v => (v && v.length <= 10) || 'Name must be less than 10 characters',
        ],
        snackbar:{
          show:false,
          color:'',
          message:''
        },
      }
    },
    mounted() {
    },
    methods:{
      goToReservation() {
        this.$router.push({path:`/backend/taquilla/reservations/${this.reservation.id}/edit`})
      },
      addSign(index) {
        this.itemSelected = index
        this.openDialog = true
      },
      closeDialog() {
        this.openDialog = false
      },
      async validateForm() {
        if (this.$refs.form.validate()) {
          for (var i of this.customers) {
            if (i['sign'] === null && i['checkin'] === true) {
              this.showSnackbarError(`Ingrese la firma del cliente ${i['fullName']}`)
              return
            }
          }
          const model = new ReservationModel
          const responsives = this.reservation.responsives || []
          const childrenNames = this.reservation.childrenNames || []
          this.loading = true
          await model.updateResponsivesSigned(this.reservation.id, this.customers, this.children, responsives, childrenNames)
          /*this.$router.push(
            {path:`/backend/taquilla/reservations/${this.reservation.id}/edit`},
            () => {
            }
          )*/
          this.loading = false
          this.showSnackbarSuccess('Se agregaron las responsivas correctamente')
          //this.loading = false
        } else {
          console.log('error')
          this.showSnackbarError('Por favor verifique que no falte un nombre o firma')
        }
      },
      handleCanvas(data) {
        if (!data.empty) {
          this.customers[this.itemSelected].sign = data.canvas.toDataURL()
          this.itemSelected = null
          this.openDialog = false
          this.panel = 0
        } else {
          let text = 'Required sign'
          if (this.lang == 'es') {
            text = 'Firma requerida'
          }
          this.showSnackbarError(text)
        }
      },
      goToToSign() {
        this.panel = 1
      },
      showSnackbarSuccess(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'green';
      },
      showSnackbarError(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'red';
      },
      async printResponsives() {
        this.loading = true
        const pdfs = new ResponsivePdf(
          this.reservation,
          this.responsiveLang,
          this.$moment.unix(this.reservation.date.seconds).format('DD-MM-YYYY')
        )
        const pdf = await pdfs.create()
        pdf.download(this.reservation.code+'-responsiva');
        this.loading = false
        /*var pdf = pdfmake.createPdf(docDefinition);
        pdf.write('pdfs/vectors.pdf').then(() => {
          console.log(new Date() - now);
        }, err => {
          console.error(err);
        });*/
      }
    },
  }
</script>