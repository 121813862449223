<style>
  #myCanvas {
    border: 1px solid grey;
  }
</style>
<template>
  <div class="">
    <canvas id="myCanvas" width="900" height="420" @mousedown="beginDrawing" @mousemove="keepDrawing" @mouseup="stopDrawing" />
    <div class="d-flex">
      <v-btn dark color="primary" @click="exportCanvas">Guardar</v-btn>
      <v-btn class="ml-2" @click="clear">Limpiar</v-btn>
      <v-btn color="error" class="ml-auto" @click="close">Cerrar</v-btn>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'SignCanvas',
    mounted() {
      this.canvas = document.getElementById("myCanvas");
      this.ctx = this.canvas.getContext('2d');
      this.empty = this.canvas.toDataURL()
    },
    methods: {
      drawLine(x1, y1, x2, y2) {
        let ctx = this.ctx;
        ctx.beginPath();
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 1;
        ctx.moveTo(x1, y1);
        ctx.lineTo(x2, y2);
        ctx.stroke();
        ctx.closePath();
      },
      beginDrawing(e) {
        this.x = e.offsetX;
        this.y = e.offsetY;
        this.isDrawing = true;
      },
      keepDrawing(e) {
        if (this.isDrawing === true) {
          this.drawLine(this.x, this.y, e.offsetX, e.offsetY);
          this.x = e.offsetX;
          this.y = e.offsetY;
        }
      },
      stopDrawing(e) {
        if (this.isDrawing === true) {
          this.drawLine(this.x, this.y, e.offsetX, e.offsetY);
          this.x = 0;
          this.y = 0;
          this.isDrawing = false;
        }
      },
      exportCanvas() {
        this.$emit('export-canvas', {
          canvas: this.canvas,
          empty: this.empty === this.canvas.toDataURL()
        })
      },
      clear() {
        const width = this.canvas.width
        this.canvas.width = width
      },
      close() {
        this.$emit('close-dialog', {close:true})
      }
    }
  }
</script>