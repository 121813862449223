import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

// https://github.com/bpampuch/pdfmake/blob/master/src/standardPageSizes.js
export default class {
  constructor(reservation, lang, date) {
    this.reservation = reservation
    this.lang = lang
    this.date = date
    this._create()
  }
  async create() {
    //var win = window.open('', '_blank');
    this.dd = {
      pageSize: 'legal',
      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      // pageMargins: [ 0, 0, 0, 0 ],
      pageMargins: [ 60, 90, 40, 40 ],
      // by default we use portrait, you can change it to landscape if you wish
      //pageOrientation: 'landscape',
      header: [
        {
          image:await this.getBase64ImageFromURL(
            process.env.VUE_APP_BASE_URL+require('@/assets/logo.png')
          ),
          width: 130,
          absolutePosition: {
            x: 60,
            y: -5
          }
        },
        {
          canvas: [
            {
              type: 'rect',
              x: 0,
              y: 0,
              w: 30,
              h: 1008,//legal
              //r: 5,//radius
              //dash: { length: 5 },
              // lineWidth: 10,
              lineColor: '#ff8300',
              color: '#ff8300',
            },
            {
              type: 'rect',
              x: 370,
              y: 0,
              w: 200,
              h: 70,
              lineColor: '#005f31',
              color: '#005f31',
            },
            {
              type: 'line',
              x1: 60, y1: 80,
              x2: 570, y2: 80,
              lineWidth: 1,
              lineColor:"#c3c3c3"
            },
            // {
            //   type: 'line',
            //   x1: 40, y1: 80,
            //   x2: 260, y2: 80,
            //   lineWidth: 10,
            //   lineCap: 'round',
            //   lineColor:"#c3c3c3"
            // },
          ],
          absolutePosition:{
            x: 0,
            y: 0,
          },
        },
        {
          text: this.reservation.code,
          style: 'reservationId',
          absolutePosition:{x: 385, y:  17}
        }
      ],
      footer: function(currentPage, pageCount) {
        return {
          text: currentPage.toString() + ' / ' + pageCount,pageCount,
          alignment: 'center'
        }
      },
      content: this.content,
      defaultStyle: {
        color: 'gray',
      },
      styles: {
        reservationId: {
          fontSize:24,
          bold: true,
          color: '#fff'
        },
      }
    }
    //pdfMake.createPdf(this.dd).open({}, win);
    return pdfMake.createPdf(this.dd)
  }
  _create() {
    const date = this.lang == 'es' ? 'Fecha ' : 'Date ';
    this.content = [
      {
        text: date+': '+this.date,
        bold:true,
        fontSize: 18,
        alignment: 'right'
      },
      '\n\n',
      this._buildText(),
      '\n',
      this._buildNameChildren(),
      '\n\n',
      this._buildSigns(),
      '\n\n',
    ]
  }
  _buildSigns() {
    let signs = {}
    const rows = Math.ceil(this.reservation.responsives.length / 3)

    for(let x = 0; x < rows; x++) {
      let col1 = ""
      let col2 = ""
      let col3 = ""
      let columns = []
      const indexCol1 = (x * 3) + 0
      if (typeof this.reservation.responsives[indexCol1] !== 'undefined' && this.reservation.responsives[indexCol1].checkin) {
        col1 = [
          {
            image:this.reservation.responsives[indexCol1].sign,
            width:150
          },
          {
            text: this.reservation.responsives[indexCol1].fullName,
            decoration: 'overline'
          }
        ]
      }
      columns.push(col1)
      const indexCol2 = (x * 3) + 1
      if (typeof this.reservation.responsives[indexCol2] !== 'undefined' && this.reservation.responsives[indexCol2].checkin) {
        col2 = [
          {
            image:this.reservation.responsives[indexCol2].sign,
            width:150
          },
          {
            text: this.reservation.responsives[indexCol2].fullName,
            decoration: 'overline'
          }
        ]
      }
      columns.push(col2)
      const indexCol3 = (x * 3) + 2
      if (typeof this.reservation.responsives[indexCol3] !== 'undefined' && this.reservation.responsives[indexCol3].checkin) {
        col3 = [
          {
            image:this.reservation.responsives[indexCol3].sign,
            width:150
          },
          {
            text: this.reservation.responsives[indexCol3].fullName,
            decoration: 'overline'
          }
        ]
      }
      columns.push(col3)
      let row = {
        alignment: 'center',
        columns: columns
      }
      signs = {...row}
    }
    return signs
  }
  _buildNameChildren() {
    let children = ''
    let counter = 0
    for(let c of this.reservation.childrenNames) {
      counter++;
      children += counter + ')' + c.fullName + ', '
    }
    return {
      text:children.slice(0, -2),
      bold: true
    }
  }
  _buildText() {
    let data = {
      ol: [],
      alignment: 'justify'
    }
    if(this.lang == 'es') {
      data.ol = this._textEs()
    } else {
      data.ol = this._textEn()
    }
    return data
  }
  _textEs() {
    return [
      'Yo, externo mi libre voluntad y deseo de realizar las actividades que ofrece la empresa denominada CENOTESZAPOTE SA DE CV, según sea el tour elegido, lo anterior bajo los siguientes términos y condiciones:',
      'Declaro que, me encuentro en pleno uso de mis facultades físicas y mentales y por ningún motivo estoy presionado o que se me ha engañado para firmar este documento, mismo que he entendido, se me ha explicado y mostrado en mi idioma natal, el cual firmo en su versión en español.',
      'Manifiesto que estoy completamente sano y que mi cuerpo no presenta lesiones o daños que me puedanimpedirrealizar alguna actividad que incluye en el tour elegido, reconociendo que en la realización de las actividadespodría presentarse inesperadamente algún percance, que pudiera causarme daños físicos o incluso la muerte, lo cual sería de mi exclusiva responsabilidad ya que reconozco el riesgo y lo asumo de manera personal y exclusiva.',
      'Que no he ingerido bebidas alcohólicas, ni drogas o enervantes,ni medicamento alguno que altere mis capacidades físicas y en todo caso asumo el riesgo que esto implica, no reservándome acción y derechoalgunoque ejercer encontra de CENOTE ZAPOTE SA DE CV nide ninguna otra persona haciéndome responsable de las con secuencias que esto podría provocar si llegara a solicitar o tomar cualquier medicamento proporcionado por el personal de la empresa será bajo mi propio riesgo.',
      'Reconozco que se me ha explicado ampliamente en que consiste las actividades que voy a desarrollar, así como los límites territoriales donde estar y los riesgos naturales a los que me enfrento al desarrollar estas actividades y las asumo de manera personal.',
      'Estoy de acuerdo eluso obligatoriode chalecos salvavidas durante las actividades acuática y para las actividades como tirolesa jungla, bicicleta atv o alguna otra que incluya el tour elegido, el uso del equipo de protección proporcionado por Cenotes Zapote SA DE CV como cascos, arnés de seguridad etc.',
      'En caso de accidentes o que incumpla con las medidas de seguridad, políticas o condiciones establecidas en este documento, CENOTE ZAPOTE SA DE CV tendrá el derecho de suspender mi tour en cualquier momento sin derecho a reembolso.',
      'En las actividades relativas y el uso de las instalaciones, estoy enterado del reglamento e instrucciones, por lo que en caso de infringir o no obedecer, serásuspendidamiactividad,eindependientemente dequeloharía bajomipropioriesgoyresponsabilidad.',
      'Asumo que el brinco de plataformas de salto, así como tirolesa acuática concaída libre a los cenotes son bajo mi propio riesgo por lo cual acepto que en caso de tener alguna lesión yo tendré que cubrir los gastos requeridos, ya que esta es una actividad opcional y que fui informado previamente del reglamento e instrucciones de la actividad.',
      'En este acto y para cualquier incidente, accidente o evento que se llegue a presentar que cause daños a mi persona, a terceros o en algún bien material, deslindo de toda responsabilidad a CENOTES ZAPOTE SA DE CV, así como a cualquiera de sus empleados, representantes legales, agentes subsidiarios, administradores, accionistas o dependientes o cualquiera relacionado con la presentación de este servicio, sin embargo Cenotes Zapote SA DE CV pondrá a disposición del cliente los primeros auxilios y/o consulta medica sin costo alguno, sin embargo si llegará a necesitar hospitalización o atención especializada aplicara la póliza de seguro que cuenta el parque únicamente con el monto estipulado en la póliza, por lo que si hay un excedente de gasto, correrá por parte del cliente el cubrir el excedente.',
      'Así mismo, en este acto asumo cualquier clase de responsabilidad civil, penal o de cualquier índole que llegue a causar las actividades a desarrollar ya sea que sufra ome vea involucradoenunaccidente ypara,encasoque estoocurra,desdeeste momentome hagoplenamenteresponsable decualquier daño que cause, tanto material, de bienes de terceros, incluso del propio equipo utilizado siendo que tendré que responder inmediatamente de estos, así como de daños a cualquier persona',
      'Para todo lo relacionado con la interpretación, cumplimiento ejecución del presente documento donde se refleja mi voluntad, acuerdo someterme a las leyes aplicables,jurisdicción y competencia de los tribunales del estado de QuintanaRooen México, renunciando expresamente acualquier foro, corte, tribunal, derecho acción que pudiera corresponderme por razón de nacionalidad o domicilio presente y futuro.',
      'Autorizo de manera voluntaria y gratuita y de acuerdo a lo señalado en la Ley Federal de Derechos de Autor, la utilización de reproducción, transmisión y retransmisión de mi imagen para cualquier fin de campaña de promoción que realice Cenotes Zapotes SA DE CV, así como la fijación de las mismas en proyecciones de video, graficas, textos y todo material suplementario a campañas publicitarias. Autorizo para que mi imagen sea utilizada durante el tiempo que sea adecuado, sin embargo, dicha autorización podrá ser restringida mediante escrito dirigido a Cenotes Zapote SA DE CV en cualquiera de sus medios de comunicación.',
      'Manifiesto que me hago responsable de los menores de edad que asisten conmigo durante todo el recorrido, estando de acuerdo que realicen actividades que aquí se lleven a cabo mencionando sus nombres a continuación:',
    ]
  }
  _textEn() {
    return [
      'I external my free will and desire to carry out the activities offered by the company called CENOTES ZAPOTE SA DE CV, depending on the chosen tour, the above under the following terms and conditions:',
      'I declare that, I am in full use of my physical and mental faculties and for no reason am I pressured or that I have been deceived to sign this document, which I have understood, it has been explained and shown to me in my native language, which I sign in its English version.',
      'I declare that I am completely healthy and that my body does not present injuries or damages that could prevent any activity included in the chosen tour, acknowledging that in carrying out the activities some mishap could occur unexpectedly, which could cause me physical harm or even death, which would be my hole responsibility since I acknowledge the risk and I assume it personally and exclusively.',
      'That I have not ingested alcoholic beverages, or drugs or enervants, or any medication that alters my physical abilities and in any case I assume the risk that this implies, not reserving any action or right to exercise against CENOTE ZAPOTE SA DE CV and of no other person making me responsible for the consequences that this could cause if I were to request or take any medication provided by the company staff will be at my own risk.',
      'I acknowledge that the activities that I am going to do have been fully explained to me, as well as the territorial limits where to be and the natural risks that I face when doing these activities and I assume them personally.',
      'I agree to the mandatory use of life jackets during water activities and for activities such as jungle zip-lining, atv, cycling or any other that includes the chosen tour, the use of protective equipment provided by Cenotes Zapote SA DE CV such as helmets, safety harness, etc.',
      'In case of accidents or failure to break with the security measures, policies or conditions established in this document, CENOTE ZAPOTE SA DE CV will have the right to suspend my tour at any time without the right to a refund.',
      'In the relative activities and the use of the installations, I am aware of the regulations and instructions, so in case of infringing or not obeying, my activity will be suspended, and regardless of whether I would do so at my own risk and responsibility.',
      'I assume that jumping from the jumping platforms, as well as the water zipline with free fall to the cenotes, are at my own risk, for which I accept that in case of injury I will have to cover the required expenses, since this is an optional activity and that I was previously informed of the regulations and instructions of the activity.',
      'In this act and for any incident, accident or event that occurs that causes damage to my person, to third parties or to any material asset, CENOTES ZAPOTE SA DE CV is not liable. as well as any of its employees, legal representatives, subsidiary agents, administrators, shareholders or dependents or anyone related to the presentation of this service, However, Cenotes Zapote SA DE CV will make first aid and / or medical consultation available to the client without cost, however if they will need hospitalization or specialized attention, the insurance policy that the park has only will apply with the amount stipulated in the policy, so if there is an excess of expense, it will be the responsibility of the client to cover the excess.',
      'Likewise, in this act I assume any kind of civil, criminal or any kind of liability that may cause the activities to be carried out, whether I suffer or see myself involved in an accident and for, if this occurs, from this moment I I am fully responsible for any damage caused, both materially, to third party goods, even to the equipment used, being that I will have to respond immediately for these, as well as for damages to any person.',
      'For everything related to interpretation, compliance with this document, where my will is reflected, I agree to submit to the applicable laws, jurisdiction and competence of the courts of the state of Quintana Roo in Mexico, giving up to express any forum, court, tribunal, right or action that may correspond to me by reason of nationality or present and future domicile.',
      'I authorize voluntarily and free of charge and in accordance with the provisions of the Federal Copyright Law, the use of reproduction, transmission and retransmission of my image for any promotional campaign carried out by Cenotes Zapotes SA DE CV as well as the fixation of the same in video projections, graphics, texts and all supplementary material to publicity campaigns. I authorize my image to be used for as long as is appropriate, however, such authorization may be restricted by writing to Cenotes Zapote SA DE CV in any of its media.',
      'I declare that I am responsible for minors who attend with me throughout the tour, agreeing that they carry out activities that are carried out here by mentioning their names below',
      '',
    ]
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }
}0